<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.675 7.58086C13.1434 7.70109 13.571 7.94493 13.913 8.28689C14.2549 8.62885 14.4988 9.05639 14.619 9.52482M16.5648 16.5645C15.0942 18.5174 12.8496 20.2661 12 20.9995C10.326 19.5542 4.8 14.6934 4.8 10.1998C4.79989 8.73001 5.24964 7.29539 6.0888 6.0887M3 2.99997L21 20.9995"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="M8.82748 3.73612C9.92522 3.19732 11.1421 2.94697 12.3634 3.00869C13.5847 3.07041 14.7701 3.44215 15.8079 4.08887C16.8457 4.73559 17.7017 5.63598 18.2952 6.70512C18.8887 7.77427 19.2001 8.97696 19.2 10.1998C19.2 11.2644 18.921 12.339 18.471 13.3794M9.41698 9.41678C9.27556 9.88441 9.26366 10.3816 9.38257 10.8555C9.50148 11.3294 9.74673 11.7621 10.0922 12.1075C10.4376 12.453 10.8704 12.6982 11.3442 12.8171C11.8181 12.936 12.3153 12.9241 12.783 12.7827"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

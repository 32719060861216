<template>
  <maps-yandex-maps-terminals v-if="appConfig.VueSettingsPreRun.MapsProvider === 'yandex'" />
  <span
    v-else
    class="v-error-color"
    v-html="translate('mapsAddress.error')"
  />
</template>

<script setup lang="ts">
const { translate } = useI18nSanitized()
const appConfig = useAppConfig()
</script>

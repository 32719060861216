<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M12 23C12 23 21 14.6598 21 9.8C21 7.46609 20.0518 5.22778 18.364 3.57746C16.6761 1.92714 14.3869 1 12 1C9.61305 1 7.32387 1.92714 5.63604 3.57746C3.94821 5.22778 3 7.46609 3 9.8C3 14.6598 12 23 12 23ZM16.0741 8.31501C16.469 7.92889 16.4761 7.29577 16.09 6.90088C15.7039 6.50599 15.0708 6.49888 14.6759 6.88499L10.875 10.6014L9.32412 9.08499C8.92923 8.69888 8.29611 8.70599 7.90999 9.10088C7.52388 9.49577 7.53099 10.1289 7.92588 10.515L10.1759 12.715C10.5645 13.095 11.1855 13.095 11.5741 12.715L16.0741 8.31501Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>

  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 14.418 12 22 12 22C12 22 4 14.418 4 10C4 7.87827 4.84285 5.84344 6.34314 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10Z"
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="M9 10L11 12L15 8"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>

<template>
  <div
    class="v-marker-select terminal"
    :class="{
      active: active,
      disabled: disabled
    }"
  >
    <icon-address-mark
      v-if="!active && !disabled"
      class="v-pointer"
    />
    <icon-address-mark-check
      v-else-if="active"
      class="v-pointer"
    />
    <icon-address-mark-slash
      v-else-if="disabled"
      class="v-pointer"
    />
  </div>
</template>

<script setup lang="ts">
import type { Terminal } from '~types/addressStore'

const { terminal } = defineProps<{
  terminal: Terminal
}>()

const clientStore = useClientStore()
const addressStore = useAddressStore()

const active = computed<boolean>(
  () => terminal.ID === clientStore.ClientState.data?.SelectedSelfserviceTerminalID
)

const isTerminalEnabled = ref<boolean>(true)

const disabled = computed<boolean>(
  () =>
    !terminal.Active ||
    !terminal.CanOrderSelfService ||
    terminal.IsTemporarilyNonActive ||
    !isTerminalEnabled.value
)

onMounted(async (): Promise<void> => {
  isTerminalEnabled.value = await addressStore
    .checkTerminal(terminal.ID)
    .then((unavailableMessage) => unavailableMessage.enableTerminal)
})
</script>

<template>
  <div class="yandex-maps-terminals">
    <div
      :id="uid"
      class="v-terminal-select-map-container"
    />
  </div>
  <template v-if="loaded && terminals.length > 0">
    <Teleport
      v-for="terminal in terminals"
      :key="`#self-service-${uid}-${sanitize(terminal.ID)}`"
      :to="`#self-service-${uid}-${sanitize(terminal.ID)}`"
    >
      <maps-common-self-service-icon :terminal="terminal" />
    </Teleport>
  </template>
</template>

<script setup lang="ts">
import type { Terminal } from '~types/addressStore'
import type { YMapBounds } from '~types/mapsStore'

import type { YMapMarker } from '@yandex/ymaps3-types'

const loaded = ref<boolean>(false)

const addressStore = useAddressStore()
const clientStore = useClientStore()
const mapsStore = useMapsStore()

const { eventOn } = useEmitter()
const { sanitize } = useI18nSanitized()
const appConfig = useAppConfig()

const terminals = computed<Terminal[]>(() =>
  (addressStore.Terminals?.data ?? [])
    .filter((t) => t.Latitude !== 0 && t.Longitude !== 0)
    .filter(
      (t) => appConfig.VueSettingsPreRun.ShowDisabledTerminals || (t.Active && t.CanOrderSelfService)
    )
)

onMounted(async () => {
  await addressStore.initTerminals()
  if (mapsStore.YandexMaps.state === 'success') {
    await makeMap()
  } else {
    await mapsStore.initYandexMaps()
  }
})

onUnmounted(() => {
  mapsStore.unmakeYMap(uid)
})

eventOn('ymaps3-loaded', async () => await makeMap())

async function makeMap(): Promise<void> {
  if (terminals.value && terminals) {
    const bounds: YMapBounds = {
      minLatitude: 9999,
      maxLatitude: 0,
      minLongitude: 9999,
      maxLongitude: 0
    }

    const placemarks: YMapMarker[] = []

    for (const terminal of terminals.value) {
      if (terminal.Latitude && terminal.Longitude) {
        const markerElement = document.createElement('div')
        markerElement.id = `self-service-${uid}-${sanitize(terminal.ID)}`
        const enabledTerminal = terminal.Active && terminal.CanOrderSelfService

        const marker = new ymaps3.YMapMarker(
          {
            id: terminal.ID,
            coordinates: [terminal.Longitude, terminal.Latitude],
            onClick: () => {
              for (const placemark of placemarks) {
                placemark.element.classList.remove('active')
              }

              clientStore.onChangeSelfServiceTerminal(terminal)
            }
          },
          markerElement
        )

        if (appConfig.VueSettingsPreRun.ShowDisabledTerminals || enabledTerminal) {
          placemarks.push(marker)

          if (terminal.Latitude > bounds.maxLatitude) bounds.maxLatitude = terminal.Latitude
          if (terminal.Longitude > bounds.maxLongitude) bounds.maxLongitude = terminal.Longitude

          if (terminal.Latitude < bounds.minLatitude) bounds.minLatitude = terminal.Latitude
          if (terminal.Longitude < bounds.minLongitude) bounds.minLongitude = terminal.Longitude
        }
      }
    }
    await mapsStore.makeYMap(uid, 'self-service', bounds)

    mapsStore.addYMapsFeatures(uid, placemarks)
  }

  loaded.value = true
}

const uid = useId()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-terminal-select-map-container {
  height: 400px;
  box-shadow: variables.$InputShadow;
  border-radius: variables.$BorderRadius;
  overflow: hidden;
}
</style>
